/* unplugin-vue-components disabled */var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "hide-header": "",
        "hide-footer": "",
        centered: "",
        "no-close-on-backdrop": "",
        "no-stacking": "",
        size: "md"
      },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c("b-alert", { attrs: { variant: "warning", show: _vm.alert.show } }, [
        _vm._v(" " + _vm._s(_vm.alert.message) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "mb-4", attrs: { "align-h": "between" } },
        [
          _c("b-col", { attrs: { cols: "10" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_logo_violet.svg"),
                alt: ""
              }
            })
          ]),
          _c("b-col", { staticClass: "text-right" }, [
            _c(
              "a",
              {
                staticClass: "tw-text-eclipse",
                attrs: { href: "javascript:;" },
                on: {
                  click: function($event) {
                    _vm.showDialog = false
                  }
                }
              },
              [
                _c("b-icon", {
                  attrs: { icon: "x-circle", "font-scale": "1.5" }
                })
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-4 pr-4" },
        [
          _c(
            "b-col",
            { attrs: { offset: "2" } },
            [
              _c("h2", { staticClass: "heading" }, [
                _vm._v(
                  " Are you sure you want to remove " +
                    _vm._s(_vm.rowData.firstname) +
                    " " +
                    _vm._s(_vm.rowData.lastname) +
                    "? "
                )
              ]),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-primary text-small" }, [
                      _vm._v("Patient")
                    ]),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(_vm.rowData.firstname) +
                        " " +
                        _vm._s(_vm.rowData.lastname) +
                        " "
                    )
                  ]),
                  _c("b-col", [
                    _c("span", { staticClass: "text-primary text-small" }, [
                      _vm._v("Birthday")
                    ]),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.rowData.birthday) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-primary text-small" }, [
                      _vm._v("Doctor")
                    ]),
                    _c("br"),
                    _vm.rowData.doctorname
                      ? _c("span", [
                          _vm._v("Dr. " + _vm._s(_vm.rowData.doctorname))
                        ])
                      : _c("span", [_vm._v("No doctor assigned.")])
                  ]),
                  _c("b-col", [
                    _c("span", { staticClass: "text-primary text-small" }, [
                      _vm._v("Date Onboarded")
                    ]),
                    _c("br"),
                    _vm._v(
                      " " + _vm._s(_vm.getDate(_vm.rowData.dateonboarded)) + " "
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", [
                    _c("span", { staticClass: "text-primary text-small" }, [
                      _vm._v("Email")
                    ]),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.rowData.email) + " ")
                  ]),
                  _c("b-col", [
                    _c("span", { staticClass: "text-primary text-small" }, [
                      _vm._v("Contact No.")
                    ]),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.rowData.phoneno) + " ")
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "removalReason" } }, [
                        _vm._v("Reason")
                      ]),
                      _c("b-form-select", {
                        attrs: {
                          id: "removalReason",
                          options: _vm.removalReasons
                        },
                        model: {
                          value: _vm.chosenReason,
                          callback: function($$v) {
                            _vm.chosenReason = $$v
                          },
                          expression: "chosenReason"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c("label", { attrs: { for: "removalNotes" } }, [
                        _vm._v("Notes")
                      ]),
                      _c("b-form-textarea", {
                        attrs: { id: "removalNotes" },
                        model: {
                          value: _vm.removalNotes,
                          callback: function($$v) {
                            _vm.removalNotes = $$v
                          },
                          expression: "removalNotes"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "outline-primary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(" Back ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            variant: "secondary",
                            disabled: _vm.buttonDisabled,
                            pill: "",
                            block: ""
                          },
                          on: { click: _vm.removePatient }
                        },
                        [_vm._v(" Remove ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }